import './App.css';
import React, { useState } from 'react';

const centerItem = 'Gamers';

/* v1.5
let items = [
  'Giga-',
  'PepeHands',
  'Qt patootie',
  'Whamen',
  'Jesus Christ, dude',
  'Back in the/my day',
  'Hell yeah, brother',
  'Ooookay dude',
  'Potato/es',
  'Mald/ing',
  'Hundred percent',
  'Jebaited',
  '*repeats unit sound*',
  'Flat earth',
  'Ah-mazin',
  'PepeLaugh',
  '(Deez) nuts',
  'Bish',
  'Cheese',
  'Listen',
  'What do you mean?',
  'He doesn\'t know',
  'Big brain',
  'Oh god',
  'What the f$%&/h$%&',
  'Reeeeeee',
  'Pog',
  'There it is',
  'I\'m dead',
  'Yooo!'
];
*/
let items = [
  'Giga-',
  'Qt patootie',
  'Ooookay dude',
  'Mald/ing',
  'Hundred percent',
  'Ah-mazin',
  '(Deez) nuts',
  'Listen',
  'What do you mean?',
  'He doesn\'t know / El no sabe',
  'Big brain',
  'Oh god',
  'What the f$%&/h$%&',
  'Reeeeeee',
  'Pog',
  'There it is',
  'I\'m dead',
  'Yooo!',
  '5Head',
  'Dents',
  'ML spawn',
  'Zhum bee tsu sah',
  'No sheep', 
  'That\'s a perma',
  'Hell yeah',
  'Falcon screech',
  'Dak so',
  '80bunti',
  'Let him cook / I\'m cooking',
  'Ilalu',
  'GG dog',
  'HUHH',
  'Cabalarios',
  'Doolers',
  'Ameno / Dorime',
  'Boom!',
  'Sacré bleu',
  'Suck on that thing',
  'It\'s a DESTRUCTION',
  'That\'s wild',
  'Let\'s fire it up'
];

function shuffle(array) {
  var currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function loadSelection() {
  const selStr = window.localStorage.getItem('beastyBingoSelection');
  let selArr = [];
  if (selStr) {
    try {
      selArr = JSON.parse(selStr);
      if (!selArr.length) {
        selArr = [];
      }
    } catch (e) {
      selArr = [];
    }
  }
  return selArr;
}

function persistSelection(item, isAdding) {
  var selArr = loadSelection();
  if (item) {
    if (isAdding) {
      selArr.push(item);
    } else {
      selArr = selArr.filter(i => i !== item);
    }
  }
  window.localStorage.setItem('beastyBingoSelection', JSON.stringify(selArr));
  window.localStorage.setItem('beastyBingoSheet', JSON.stringify(items));
  window.localStorage.setItem('beastyBingoLastUpdate', new Date().toUTCString());
}

function clearStorage() {
  window.localStorage.setItem('beastyBingoSelection', null);
  window.localStorage.setItem('beastyBingoSheet', null);
  window.localStorage.setItem('beastyBingoLastUpdate', null);
}


let loaded = false;
try {
  let loadSheet = true;
  let lastUpdate = window.localStorage.getItem('beastyBingoLastUpdate');
  if (lastUpdate) {
    let lastUpdateDate = new Date(lastUpdate);
    if (!isNaN(lastUpdateDate.getDate())) {
      if (lastUpdateDate.getTime() < new Date().getTime() - 43200000) {
        loadSheet = false;
        clearStorage();
      }
    }
  }
  if (loadSheet) {
    let sheet = JSON.parse(window.localStorage.getItem('beastyBingoSheet'));
    if (sheet && sheet.length) {
      items = sheet;
      loaded = true;
    }
  }
} catch (e) {
}
if (!loaded) {
  shuffle(items);
}

function App() {

  window.addEventListener('hashchange', () => window.location.reload());

  const [bingo, setBingo] = useState(false);

  function checkBingo(setY, setX, checked) {
    for (let y = 0; y < 5; y++) {
      let isBingo = true;
      for (let x = 0; isBingo && x < 5; x++) {
        isBingo &= y === setY && x === setX ? checked : selections[y*5 + x][0];
      }
      if (isBingo) {
        setBingo(true);
        return;
      }
    }

    for (let x = 0; x < 5; x++) {
      let isBingo = true;
      for (let y = 0; isBingo && y < 5; y++) {
        isBingo &= y === setY && x === setX ? checked : selections[y*5 + x][0];
      }
      if (isBingo) {
        setBingo(true);
        return;
      }
    }

    let isBingo = true;
    for (let y = 0; isBingo && y < 5; y++) {
      isBingo &= y === setY && y === setX ? checked : selections[y*5 + y][0];
    }
    if (isBingo) {
      setBingo(true);
      return;
    }
    
    isBingo = true;
    for (let y = 0; isBingo && y < 5; y++) {
      isBingo &= y === setY && 4 - y === setX ? checked : selections[y*5 + 4 - y][0];
    }
    if (isBingo) {
      setBingo(true);
      return;
    }

    setBingo(false);
  }

  function handleClick(y, x) {
    const newState = !selections[y*5 + x][0];
    selections[y*5 + x][1](newState);
    persistSelection(x === 2 && y === 2 ? centerItem : items[y*5 + x], newState);
    checkBingo(y, x, newState);
  }

  const selection = loadSelection();
  const selections = [
    useState(selection.indexOf(items[0]) >= 0),
    useState(selection.indexOf(items[1]) >= 0),
    useState(selection.indexOf(items[2]) >= 0),
    useState(selection.indexOf(items[3]) >= 0),
    useState(selection.indexOf(items[4]) >= 0),
    useState(selection.indexOf(items[5]) >= 0),
    useState(selection.indexOf(items[6]) >= 0),
    useState(selection.indexOf(items[7]) >= 0),
    useState(selection.indexOf(items[8]) >= 0),
    useState(selection.indexOf(items[9]) >= 0),
    useState(selection.indexOf(items[10]) >= 0),
    useState(selection.indexOf(items[11]) >= 0),
    useState(selection.indexOf(centerItem) >= 0),
    useState(selection.indexOf(items[13]) >= 0),
    useState(selection.indexOf(items[14]) >= 0),
    useState(selection.indexOf(items[15]) >= 0),
    useState(selection.indexOf(items[16]) >= 0),
    useState(selection.indexOf(items[17]) >= 0),
    useState(selection.indexOf(items[18]) >= 0),
    useState(selection.indexOf(items[19]) >= 0),
    useState(selection.indexOf(items[20]) >= 0),
    useState(selection.indexOf(items[21]) >= 0),
    useState(selection.indexOf(items[22]) >= 0),
    useState(selection.indexOf(items[23]) >= 0),
    useState(selection.indexOf(items[23]) >= 0)
  ];

  persistSelection();

  return window.location.hash === '#items'
    ? (
      <div className="app">
        <div className="beasty-logo">Beasty Bingo items</div>
        <ul class="items">
          <li>Center item: {centerItem}</li>
          {(function() {
            items.sort();
            return items.map(i => <li>{i}</li>);
          })()}
        </ul>
        <div className="app-links">
          <a href="#game" className="app-link">Back to game</a>
        </div>
      </div>
    )
    : (
      <div className="app">
        <div className="beasty-logo">Beasty Bingo</div>
        <div className={bingo ? "bingo-active" : "bingo-pending"}>BINGO!</div>
        <table>
          <tbody>
            {[0, 1, 2, 3, 4].map(
              y => {
                return (<tr key={"row-" + y}>
                  {[0, 1, 2, 3, 4].map(
                    x => {
                      return x === 2 && y === 2
                        ? (<td key={"cell-" + y + "-" + x} className={selections[y*5 + x][0] ? 'selected' : null} onClick={function(e) { handleClick(y, x) }}>{centerItem}</td>)
                        : (<td key={"cell-" + y + "-" + x} className={selections[y*5 + x][0] ? 'selected' : null} onClick={function(e) { handleClick(y, x) }}>{items[y*5 + x]}</td>);
                  })}
                </tr>);
              })
            }
          </tbody>
        </table>
        <div className="app-links">
          <a className="app-link" href="#game" onClick={function(e) { clearStorage(); window.location.reload(); e.preventDefault(); }}>New sheet</a>
          <a className="app-link aside" href="#items">Item list</a>
        </div>
      </div>
    );
}

export default App;
